import React, { useEffect, useState } from 'react'
import {
  PageContent,
  CreateOrganizationButton,
  Header,
  OrganizationCard,
  ButtonSize,
  PagesHeader,
  SearchWithoutButton,
  Button,
  ButtonType
} from 'components'
import LoadingOrganizationCard from 'components/pages/organizations/loading-organization-card'
import { motion } from 'framer-motion'
import { fadeIn } from 'public/animation/motion'
import { BiWorld } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'

import { StorageProvider, useStorage } from 'contexts/storage-context'
import { Guards } from 'util/enums'
import { OrganizationsData } from 'hooks/storage/useOrganizations'
import { useRouter } from 'next/router'
import { paths } from 'config/paths'

export default function OrganizationWebsites() {
  const { t } = useTranslation()
  const router = useRouter()

  const { organizationsData, loadingOrganizations } = useStorage()
  const [organizationsDataFiltered, setOrganizationsDataFiltered] = useState<
    OrganizationsData[] | undefined
  >([])

  useEffect(() => {
    if (!loadingOrganizations) {
      setOrganizationsDataFiltered(organizationsData)
    }
  }, [loadingOrganizations, organizationsData])

  function filterOrganizationsByName(name: string) {
    if (!organizationsDataFiltered || !name) {
      setOrganizationsDataFiltered(organizationsData)
      return
    }

    setOrganizationsDataFiltered(
      organizationsDataFiltered.filter(organization =>
        organization.name.toLowerCase().includes(name.toLowerCase())
      )
    )
  }

  return (
    <PageContent header={
      <Header
        endButtons={[
          <Button
            buttonSize={ButtonSize.Small}
            onClick={() => {
              router.push(paths.globalDashboard)
            }}
            buttonType={ButtonType.Tertiary}
          >
            {t('globalDashboard.title')}
          </Button>,
          <CreateOrganizationButton buttonSize={ButtonSize.Small} />
        ]}
      />}>

      <PagesHeader
        title={t('organizations.page description')}
        subtitle={t('organizations.page description')}
        className="mb-5"
      />

      <div className="mb-5 px-10">
        <SearchWithoutButton
          placeholder={t('organizations.searchPlaceholder')}
          onInputChange={filterOrganizationsByName}
        />
      </div>

      {loadingOrganizations ? (
        <>
          {Array.from({ length: 4 }, (_, i) => i + 1).map((item, index) => {
            return <LoadingOrganizationCard key={index} />
          })}
        </>
      ) : organizationsDataFiltered && organizationsDataFiltered.length > 0 ? (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          {organizationsDataFiltered?.map((item, index) => {
            return <OrganizationCard item={item} key={index} />
          })}
        </motion.div>
      ) : (
        <div className="h-96 flex items-center flex-col justify-center w-full">
          <BiWorld className="text-gray-200" size={70} />
          <div className="text-gray-200 mt-5 mb-2">
            {t("organizations.You don't have any organization")}
          </div>
          <CreateOrganizationButton />
        </div>
      )}
    </PageContent>
  )
}

OrganizationWebsites.storageProvider = StorageProvider
OrganizationWebsites.guard = Guards.OnlyAuthorized
