import React from 'react'

export default function LoadingOrganizationCard() {
  return (
    <div className="animate-pulse px-10 pb-1">
      <div className=" flex border rounded-sm border-white-200 dark:border-gray">
        <div className="w-20 h-20 m-3 bg-white-200  dark:bg-gray rounded-md"></div>
        <div className="flex-1 flex justify-center flex-col">
          <div className="text-lg bg-white-200  dark:bg-gray	w-24 h-6 rounded "></div>
          <div className="bg-white-200  dark:bg-gray w-20 rounded h-6 mt-2"></div>
        </div>
        <div className="flex items-end p-2">
          <div className="h-8 w-16 px-3 bg-white-200 dark:bg-gray duration-200 rounded mr-1"></div>
          <div className="h-8 w-16 px-3 bg-white-200 dark:bg-gray duration-200 rounded"></div>
        </div>
      </div>
    </div>
  )
}
